require('./styles/site-bootstrap.scss');

// load all of bootstrap's javascript.
// require('bootstrap');

// you can also explicitly include individual bootstrap plugins here like this.
// this is the minimum needed for the app's default functionality
require('bootstrap/js/dist/collapse'); // mobile menus
require('bootstrap/js/dist/dropdown'); // navigation dropdowns
require('bootstrap/js/dist/alert'); // alerts (close button)
require('bootstrap/js/dist/modal'); // modals (used by teams)
